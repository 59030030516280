<template>
  <div id="catalogHash" class="page-catalog">
    <div class="container">
      <div class="page-catalog__header">
        <div class="page-catalog__nav">
          <locale-router-link to="" class="crumbs-link main-page-link">
            {{ $t("home") }}</locale-router-link
          >
          <a class="crumbs-link page-catalog-link">
            {{ $t("discountCatalog") }}</a
          >
        </div>
        <h2 class="title">Скидки</h2>
        <p class="catalog__content">
          Знакомьтесь со скидками Party Club! Здесь мы собрали все самые свежие
          новинки и укрепленные временем напитки с привлекательной скидкой,
          только для вас.
        </p>
        <hr class="page-catalog__line" />
        <div class="page-catalog__search desktop__search">
          <input
            class="page-catalog__filter-title"
            placeholder="Быстрый поиск"
          />
          <img src="@/assets/img/icons/search.svg" alt="" />
        </div>
        <div class="mobile__buttons">
          <button
            class="page-catalog-filter"
            :class="{ 'is-active': isShowFilter }"
            @click="showFilter"
          >
            {{ $t("filter") }}
          </button>
          <div
            class="page-catalog__sorting-btn mobile"
            @click="isShowSort = !isShowSort"
          >
            {{ $t("sorting") }}
          </div>
        </div>
      </div>
      <div class="page-catalog__content">
        <discount-filters
          @load-products-by-filter="loadProductsByFilter"
          @close-filter="isShowFilter = false"
          :isShowFilter="isShowFilter"
        />

        <div class="page-catalog__products">
          <div class="page-catalog__sorting">
            <div class="page-catalog__found">
              <span>{{ $t("found") }}</span>
              <span
                v-if="countProducts || countProducts === 0"
                class="number-found-product"
                >{{ countProducts }}</span
              >
              <span> {{ $t("goods") }}</span>
            </div>

            <div
              class="page-catalog__sorting-btn desktop"
              @click="isShowSort = !isShowSort"
            >
              {{ $t("sorting") }}
              <img src="@/assets/img/icons/arrow-gray.svg" alt="" />
            </div>
            <discount-sort
              v-if="isShowSort"
              @sort-btn="isShowSort = false"
              @sort-start="getFiltredProducts"
            />
          </div>
          <div class="page-catalog__items">
            <template v-if="products">
              <product-card
                v-for="product in products"
                :key="product.id"
                :item="product"
              />
            </template>

            <button
              v-if="!isLastPage"
              class="catalog-filter-more"
              @click="nextPage"
            >
              {{ $t("seeAll") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { actionTypes } from "@/store/modules/catalog";

export default {
  name: "Catalog",
  components: {
    ProductCard: () => import("@/components/products/ProductCard.vue"),
    DiscountFilters: () => import("@/components/discount/DiscountFilters.vue"),
    DiscountSort: () => import("@/components/discount/DiscountSort.vue"),
  },
  data() {
    return {
      pagination: {
        page: 1,
        pageSize: 15,
      },
      isShowFilter: false,
      isLastPage: false,
      isShowSort: false,
    };
  },
  computed: {
    ...mapState("catalogModule", {
      products: "data",
      isLoading: "isLoading",
    }),
    ...mapState("cityModule", {
      selectedCity: "selectedCity",
    }),
    ...mapGetters("catalogModule", ["isFilterEmpty"]),
    countProducts() {
      return this.products?.length;
    },
  },
  watch: {
    isFilterEmpty: {
      handler(newVal) {
        if (!newVal) {
          this.getFiltredProducts();
        }
      },
    },
    "$route.query.city_id": {
      handler() {
        if (this.$isMobile()) {
          this.getFiltredProducts({
            pagination: this.pagination,
            isPaginate: true,
          });
        } else {
          this.getFiltredProducts();
        }
      },
    },
  },
  created() {
    if (!this.$route.query.filters) {
      if (this.$isMobile()) {
        this.getFiltredProducts({
          pagination: this.pagination,
          isPaginate: true,
          cityId: this.$route.query.city_id,
        });
      } else {
        this.getFiltredProducts({ cityId: this.$route.query.city_id });
      }
    }
  },
  beforeDestroy() {
    this.clearFilters();
    const { query } = this.$route;
    const qs = { city_id: query.city_id };
    this.$router.replace({
      query: qs,
    });
  },
  methods: {
    ...mapActions("catalogModule", {
      getFiltredProducts: actionTypes.getFiltredProducts,
      clearFilters: actionTypes.clearFilters,
    }),

    showFilter() {
      this.isShowFilter = !this.isShowFilter;
    },
    loadProductsByFilter() {
      this.getFiltredProducts({ cityId: this.$route.query.city_id });
    },
    nextPage() {
      this.pagination.page++;
      this.getFiltredProducts({
        pagination: this.pagination,
        isPaginate: true,
      }).then(({ data, meta }) => {
        if (
          meta.pagination?.pageCount === this.pagination.page ||
          data?.length === 0
        ) {
          this.isLastPage = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
